import React, {useState, useContext, useEffect} from "react";
//import "./Button.scss";
import { StoreContext } from '../../store/store';
import Button from '../Button/Button';
import {text} from '../../utils/global';
import {ServiceRequest,handleLink} from '../../utils/Services';
import Timer from "../Timer/Timer";
import {SPLASH_LOAD} from "../../constants/actionTypes";
import analyticsTealium from "../../utils/Analytics/analytics";

const EndFlow = props => {
    let { state, dispatch } = useContext(StoreContext);
    let buttons = [], timer = -1, count = 0;
    const [redirectButtonType, setRedirectButtonType] = useState("primary");
    const [redirectMsg , setRedirectMsg] = useState('');
    const [timerCount, setTimerCount] = useState (0);
    let endTimer = false;
    let title, subTitle = false;
    const { trackPageEvent } = analyticsTealium();
    //const [count, setCount] = useState(0);
     //DOM load event
     useEffect(() => {
         if(window.config !== undefined){
            setRedirectMsg(' ' + text('timer.units.in.seconds'));
            setTimerCount(process.env.REACT_APP_AUTO_REDIRECT_IN_SEC);
         }
      });

    if(window.config !== undefined && (state.data.links !== null || state.data.links !== undefined)){
        //Redirect button for END_FLOW context
        if(state.data.links['urn:vodafoneid:redirect']){
            //startTimer();
            buttons.push(
            <div className="btn-wrapper" key="btn2">
                    <Button
                    type={redirectButtonType}
                    label={text('redirect.me.now.button.label')}
                    id="redirectButton"
                    handleClick={(event) => { trackPageEvent(state.data.context, {eventCategory: "button", eventLabel: text('redirect.me.now.button.label') }), redirectLinkHandler(event)}}
                    />
                </div>
            );
            if( state.page_look !== "mva" ){
                endTimer = <Timer display={true} text ={redirectMsg} countdown={process.env.REACT_APP_AUTO_REDIRECT_IN_SEC} units = {redirectMsg} action = { () => {redirectService()}} ></Timer>
            }
        }

        //Return to log in service
        if(state.data.links['urn:vodafoneid:back']){
            //startTimer();
            buttons.push(
            <div className="btn-wrapper no-margin" key="btn3">
                    <Button
                    type={redirectButtonType}
                    label={text('return.to.log.in.button.label')}
                    id="returnButton"
                    handleClick={() => returnLinkHandler()}
                    />
                </div>
            );
        }

         //Return to Service page in REgistration success
        if(state.data.links['urn:vodafoneid:follow']){
            //startTimer();
            buttons.push(
            <div className="btn-wrapper no-margin" key="btn3">
                    <Button
                    type={redirectButtonType}
                    label={text('return.to.account.button.label')}
                    id="returnButton"
                    handleClick={() => returnServiceHandler()}
                    />
                </div>
            );
        }

         // Try again
         if(state.data.links['urn:vodafoneid:tryagain']){
            //startTimer();
            buttons.push(
            <div className="btn-wrapper no-margin" key="btn3">
                    <Button
                    type={redirectButtonType}
                    label={text('try.again.button.label')}
                    id="tryAgainnButton"
                    handleClick={() => tryAgainHandler()}
                    />
                </div>
            );
        }
    }

    function redirectLinkHandler () {
        setRedirectButtonType("primary spinner disabled");
        redirectService();
        handleLink(state.data.links['urn:vodafoneid:redirect'], props.RequestCallBack,'',null,'Click on - Redirect Me','Clicked on Redirect Me');
    }

    function redirectService () {
        dispatch({
            type: SPLASH_LOAD,
            splash: true
        });
        handleLink(state.data.links['urn:vodafoneid:redirect'], props.RequestCallBack,'',null,'Redirect to service','Redirect to service');
    }

    function tryAgainHandler () {
        dispatch({
            type: SPLASH_LOAD,
            splash: true
        });

        // Simple redirection
        if( state.data && state.data.links['urn:vodafoneid:tryagain'] ){
            window.location.href = state.data.links['urn:vodafoneid:tryagain'].href;
        }
    }

    function returnServiceHandler () {

        setRedirectButtonType("primary spinner disabled");

        let logInfo = {
            event_desc : 'Click on - Go to my account Button',
            event_msg : 'URL: ' + state.data.links['urn:vodafoneid:follow'].href ,
            resp_code : '',
            page: state.data.context,
            fragment :  state.data.fragment !== undefined ? state.data.fragment : '',
            // market : state.data.nv !== undefined && state.data.nv === true ? 'NV' : market,
            service_name: state.data.context === undefined || state.data.context === '' ? '' : state.data.clientInfo === undefined || state.data.clientInfo === null ? '' : state.data.clientInfo.client_name
          };
          ServiceRequest(state.data.links['urn:vodafoneid:follow'],null,props.RequestCallBack,'self',logInfo);
    }

    function returnLinkHandler () {
        setRedirectButtonType("primary spinner disabled");

        let logInfo = {
            event_desc : 'Click on - Return to log in Button',
            event_msg : 'URL: ' + state.data.links['urn:vodafoneid:back'].href ,
            resp_code : '',
            page: state.data.context,
            fragment :  state.data.fragment !== undefined ? state.data.fragment : '',
            // market : state.data.nv !== undefined && state.data.nv === true ? 'NV' : market,
            service_name: state.data.context === undefined || state.data.context === '' ? '' : state.data.clientInfo === undefined || state.data.clientInfo === null ? '' : state.data.clientInfo.client_name
          };
          ServiceRequest(state.data.links['urn:vodafoneid:back'],null,props.RequestCallBack,'self',logInfo);
    }

    return (
        <div className="section-form">
            {buttons}
            {endTimer}
        </div>
    );
};

export default EndFlow;
