
import React, { useState, useEffect, useContext } from "react";
import { StoreContext } from '../../store/store';
import Button from '../Button/Button';
import FormErrorBox from '../FormErrorBox/FormErrorBox';
import {text} from "../../utils/global";
import {handleLink} from '../../utils/Services';
import SectionTitle from '../SectionTitle/SectionTitle';
import Parser from 'html-react-parser';
import analyticsTealium from "../../utils/Analytics/analytics";

const EndFlowLoading = props => {
  let { state } = useContext(StoreContext);
  let buttons = [];
  let backendMsg = false;
  let redirectTimeout = process.env.REACT_APP_REDIRECT_TIMEOUT;
  const [redirectButtonType, setRedirectButtonType] = useState("primary disabled");
  const [errorMsg, setErrorMsg] = useState(Parser(text(state.data.message.value,'',false)));
  const [title, setTitle] = useState('page.title.for.confirm_pin.page');
  const [subTitle, setSubTitle] = useState('page.intro.for.confirm_pin.page');
  const { trackPageEvent } = analyticsTealium();
  let sec = '';
  let min = '';
  useEffect( () => {
    setTitle('page.title.for.confirm_pin.page');
    setSubTitle(text('page.intro.for.confirm_pin.page'));
    sec = String(redirectTimeout % 60);
    sec = sec.length == 1 ? '0' + sec : sec;
    min = String(Math.floor(redirectTimeout / 60));
    min = min.length == 1 ? '0' + min : min;
    setErrorMsg(Parser(text(state.data.message.value,'',false).replace('{1}', min + ":" + sec )));
    //CONFIRM PIN - set title for NV flow
    if(state.data.nv === true && state.data.identifier.type === 'email'){
      setSubTitle(text("page.intro.for.confirm_pin.page.nv"));
    }
     let timer =  setInterval(() =>
        {
            let errorMsg1 = String(text(state.data.message.value,'',false));
            if( redirectTimeout > 60 ){
                // Show minutes and seconds
                redirectTimeout = redirectTimeout - 1;
                sec = String(redirectTimeout % 60);
                sec = sec.length == 1 ? '0' + sec : sec;
                min = String(Math.floor(redirectTimeout / 60));
                min = min.length == 1 ? '0' + min : min;
                errorMsg1 = errorMsg1.replace('{1}', min + ":" + sec);
                setErrorMsg(Parser(errorMsg1));
            }else if(redirectTimeout > 0){
                // Show seconds
                redirectTimeout = redirectTimeout - 1;
                errorMsg1 = errorMsg1.replace('{1}', "00:" + redirectTimeout);
                setErrorMsg(Parser(errorMsg1));
                /// Time is out
            }else if( redirectTimeout == 0){
                clearInterval( timer );
                setErrorMsg(text('too.many.pins.message.post.timer'));
                setRedirectButtonType('primary');
            }
        }, 1000);
  }, []);

//Buttons / links
if(state.data.links !== null || state.data.links !== undefined){
    let msg = text(state.data.message.value);
    if(msg !== undefined){
        //setErrorMsg(msg.replace('{1}',(Math.floor(redirectTimeout / 60)) + ":" + redirectTimeout % 60  + ' minutes'));
    }
  //Redirect button for END_FLOW_LOADING context
  if(state.data.links['urn:vodafoneid:redirect']){
    buttons.push(
      <div className="btn-wrapper" key="btn2">
            <Button
              type={redirectButtonType}
              label={text('retry.button.label')}
              id="redirectButton"
              handleClick={(event) => {trackPageEvent(state.data.context, {eventCategory: "button", eventLabel: text('retry.button.label') }), redirectLinkHandler(event)}}
            />
          </div>
    );
    backendMsg = <FormErrorBox messageClass="form-error" error={errorMsg} iconType={`icon_${state.data.message.type}`} messageType={`message_${state.data.message.type}`} />;
    }
}

function redirectLinkHandler () {
  setRedirectButtonType("primary spinner disabled");
  let logInfo = {
    event_desc : 'Click on - Redirect Me',
    event_msg : 'URL: ' + state.data.links['urn:vodafoneid:redirect'].href,
    page: state.data.context,
    fragment :  state.data.fragment !== undefined ? state.data.fragment : '',
    market : state.data.nv !== undefined && state.data.nv === true ? 'NV' : 'VF',
    service_name: state.data.context === undefined || state.data.context === '' ? '' : state.data.clientInfo === undefined || state.data.clientInfo === null ? '' : state.data.clientInfo.client_name
  };
  handleLink(state.data.links['urn:vodafoneid:redirect'], props.RequestCallBack,'',null,logInfo);
}

    return (
      <>
      <SectionTitle title = {title} subTitle={subTitle}></SectionTitle>
      {backendMsg}
        <div className="section-form">
          <div className="box">
            {state.errorObj && state.errorObj.msg && (<FormErrorBox messageClass="form-error" error={text(state.errorObj.msg)} iconType="icon_error" messageType="message_error" />)}
          </div>
          {buttons}
        </div>
      </>
    );
}

export default EndFlowLoading;