import React, {
    useEffect,
    useContext,
    useState,
    useRef
} from "react";

import PropTypes from 'prop-types';

// Stying
import "./input-otp.scss";

export const InputOTP = props => {

    const [OTP, setOTP] = useState(props.otp);

    const inputRef0 = useRef();
    const inputRef1 = useRef();
    const inputRef2 = useRef();
    const inputRef3 = useRef();
    const inputRef4 = useRef();
    const inputRef5 = useRef();
    
    const template = [inputRef0, inputRef1, inputRef2, inputRef3, inputRef4, inputRef5];

    let refsList = [];

    for( let index = 0; index < props.size && props.size <= 6; index ++ ){
        refsList.push(template[index] );
    }

    // const refsList = [inputRef0, inputRef1, inputRef2, inputRef3, inputRef4, inputRef5];

    useEffect(() => {
        // if (OTP === "") {
        //     refsList[0].current.focus();
        // }

    });

    useEffect(() => {
        setOTP(props.otp);

        // if (OTP === "") {
        //     refsList[0].current.focus();
        // }

        return () => {
        };

    }, [props.otp]);

    const handleOTPInputKeyDown = (e, index) => {
        let value = e.target.value;

        // delete
        if (index > 0 && e.keyCode === 8) {
            let value = e.target.value;
            let currentOTP = OTP;

            currentOTP = currentOTP.substring(0, currentOTP.length - 1);

            setOTP(currentOTP);

            // focus previous input
            if (value === "") {
                if (index > 0) {
                    refsList[index - 1].current.value = "";
                    refsList[index - 1].current.focus();
                }
            }

            setOTP(currentOTP);

            if (props.onHandleChange) {
                props.onHandleChange(currentOTP);
            }
        }
    }

    const handleInputOTPFocus = () => {
        // focus next empty input
        if (OTP.length < refsList.length) {
            for (let i = 0; i < refsList.length - 1; i++) {

                // multiple value e.g. 123
                if (refsList[i].current.value.length > 1) {
                    // short multiple value e.g. 1234
                    if (i + refsList[i].current.value.length < refsList.length - 1) {
                        refsList[i + refsList[i].current.value.length].current.focus();

                        // long multiple value e.g. 12345678910
                    } else {
                        refsList[refsList.length - 1].current.focus();
                    }
                    return;
                }

                // single value e.g. 1
                if (refsList[i].current.value === "") {
                    refsList[i].current.focus();
                    return;
                }
            }

            // focus last input

        } else {
            refsList[refsList.length - 1].current.focus();
        }
    }

    const handleInputOTPChnage = (e, index) => {
        // allow only digits
        const regex = /^\d*[.]?\d*$/;

        let value = e.target.value;
        let currentOTP = OTP;

        if (OTP.length < refsList.length) {
            if (regex.test(value)) {
                currentOTP += value;
                currentOTP = currentOTP.substring(0, refsList.length);

                setOTP(currentOTP);

                if (value !== "" && value.length === 1) {
                    if (index < refsList.length - 1) {
                        refsList[index + 1].current.focus();
                    }

                    // multiple value e.g. 123
                } else if (value !== "" && value.length > 1) {

                    // focus next empty input
                    if (currentOTP.length < refsList.length) {
                        refsList[currentOTP.length].current.focus();

                        // focus last input
                    } else {
                        refsList[refsList.length - 1].current.focus();
                    }
                }
            }

            setOTP(currentOTP);

            // console.log("OTP: " + OTP);

            if (props.onHandleChange) {
                props.onHandleChange(currentOTP);
            }
        }

        if (OTP === "") {
            refsList[0].current.focus();
        }
    }

    return (
        <div className={"vf-input-otp-wrapper" + " size-" + props.size}>

            <div className="vf-input-otp-label">
                <span>{props.label}</span>
            </div>

            <div className={"vf-input-otp" + (props.secured ? " secured" : "")}>
                {refsList.map((ref, i) => {
                    return (
                        <div className="vf-input-otp-part-wrap">
                            <input ref={ref} type="tel" id={props.id + i} name={props.name + i} className="vf-input-otp-part" value={OTP.charAt(i)} autoComplete="off" tabIndex={props.tabIndex} onFocus={handleInputOTPFocus} onKeyDown={(e) => handleOTPInputKeyDown(e, i)} onChange={(e) => handleInputOTPChnage(e, i)} />
                            <span className="dot"></span>
                        </div>
                    )
                })}
            </div>

        </div>
    )
}

InputOTP.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    size: PropTypes.oneOf([3, 4, 5, 6]),
    secured: PropTypes.bool,
    onHandleChange: PropTypes.func
};

InputOTP.defaultProps = {
    label: "Please enter one-time PIN",
    size: 6,
    otp: "",
    secured: true
};

export default InputOTP;