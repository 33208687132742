import axios from 'axios';
import VFLogger from './Logger';
import { checkWebView } from './global';
import { INITIAL_START } from '../constants/actionTypes';
import { replaceIFrameSrc } from './utils';

export const configRequest = (dispatch) => {
  return new Promise(function (resolve, reject) {
    dispatch({
      type: INITIAL_START,
    });

    console.log('REACT_APP_BACKEND_ENDPOINT: ' + process.env.REACT_APP_BACKEND_ENDPOINT);
    console.log('REACT_APP_INDEX_ENDPOINT: ' + process.env.REACT_APP_INDEX_ENDPOINT);

    let index = process.env.REACT_APP_BACKEND_ENDPOINT + process.env.REACT_APP_INDEX_ENDPOINT;

    console.log('NODE_ENV: ' + process.env.NODE_ENV);

    if (process.env.NODE_ENV !== 'development') {
      index = window.location.href.substr(0, 11) + index.substr(8);
    }

    const options = {
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json; charset=utf-8',
      },
    };

    // Console log
    console.log('URL for config fetch : ' + index);
    axios
      .get(index, { options })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        let errorObj = {};
        if (error.response !== undefined) {
          errorObj = {
            data: error.response.data,
            status: error.response.status,
          };
        } else {
          errorObj = {
            data: 'oops.message',
            status: 500,
          };
        }
        reject(errorObj);
      });
  });
};

export const ServiceRequest = (requestUrl, requestObj = null, callBack, target = null, logInfo = null) => {
  //Console log
  console.log('Service Request call : ' + requestUrl);
  const page = logInfo ? logInfo.page : '';
  if (requestUrl.type && requestUrl.type === 'text/html') {
    if (target === null && checkWebView() === '_blank') {
      window.open(requestUrl.href);
    } else {
      window.location.href = requestUrl.href;
    }
  } else {
    let ajaxHeaders = {
      Accept: 'application/json',
      'Content-type': 'application/json; charset=utf-8',
    };

    for (var key in requestUrl.headers) {
      if (Object.prototype.hasOwnProperty.call(requestUrl.headers, key)) {
        //if (requestUrl.headers.hasOwnProperty(key)) {
        ajaxHeaders[key] = requestUrl.headers[key];
      }
    }

    let url = requestUrl.href;
    //Sending redirect url for confirm reset password contect to show button in end flow context
    if (window.context !== undefined && window.context.context === 'CONFIRM_RESET_PASSWORD') {
      let redirectURL = window.location.search;
      url = url + redirectURL;
    }
    //VFLogger.info('page requested' + logInfo);
    VFLogger.info(`${page}: request ${url}`);
    axios.defaults.withCredentials = true;
    axios({
      method: requestUrl.method,
      url: url,
      data: requestObj,
      headers: ajaxHeaders,
      timeout: process.env.REACT_APP_AJAX_TIMEOUT,
      withCredentials: true,
    })
      .then((response) => {
        /** WAF FIX */
        const contentType = response.headers['content-type'];
        if (contentType && contentType.includes('text/html')) {
          document.body.innerHTML = replaceIFrameSrc(response.data);
          return;
        }
        /** WAF FIX END */
        const enabledContext = {
          //context: "Validate",
          //context: "Validate_GB",
          //context: "Validate_PT",
          // context: "Validate_ES",
          // context: "Validate_forgot_username"
          context: 'Authenticate',
          // context: "Authenticate_NV",
          // context: "EndFlowLoading"
          // context: "Authenticate_DE"
          // context: "Authenticate_GB",
          //context: "Update_Profile"
          //context: "END_FLOW_LOADING"
          // context: "Confirm_Pin_VF",
          //context: "Confirm_Pin_NV",
          // context: "Confirm_Pin_NV_MISDN",
          // context: "Registration"
          // context: "Confirm_Reset_Password"
          // context: "Confirm_Pin_Password"
          // context: "Error"
          // context: "WelcomeBack_VF"
          // context: "WelcomeBack_IT"
          //  context: "WelcomeBack_NV"
          // context: "WelcomeBack_DE",
          // context: "NonVodafoneEnabled"
          // context: "SELFCARE_KYC"
          // context: "ES_EMAIL_VERIFICATION"
          // context: "Follow",
          // context: "End_Flow",
          // context: "End_Flow_Pwd"
          //context: "Confirm_Reset_Pwd_Error"
          // context: "End_flow_Pwd_Expire",
          // context: "End_flow_Success"
          // context: "End_Flow_Pwd",
          // context: "Account_Selection",
          // context: "Account_Selection_Soho"
        };

        if (process.env.NODE_ENV === 'development') {
          response.data = response.data[enabledContext.context];
        }
        if (response.data.context) {
          VFLogger.info(`${page}: process: ${response.data.context}`);
        }
        callBack(response);
      })
      .catch((error) => {
        console.log(error);
        VFLogger.info(`${page}: failed: ${error}`);
        let errorObj = {};

        if (error.response !== undefined) {
          errorObj = {
            data: error.response.data,
            status: error.response.status,
          };
        } else {
          let status = 500; // Internal server error
          //Check the http call timeout
          if (error.code === 'ECONNABORTED') {
            status = 408; //Timeout code
          }
          errorObj = {
            data: 'oops.message',
            status: status,
          };
        }
        callBack(errorObj);
      });
  }
};

export default ServiceRequest;

export function handleLink(link, callBack, target = null, logInfo = null) {
  ServiceRequest(link, null, callBack, target, logInfo);
}
