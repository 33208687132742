import React, { useState, useEffect, useContext, useRef } from "react";

import "./Input.scss";

const Input = props => {
    const [focusState, setFocusState] = useState(false);

    const handleFocus = (e) => {
        setFocusState(true);
        if(props.handleFocus) {
            props.handleFocus(e);
        }
    }

    const handleBlur = (e) => {
        setFocusState(false);

        if(props.handleBlur){
            props.handleBlur(e);
        }
    }

    return (
        <>
            <div className="standalone-field-label">{props.label}</div>

            <div className={props.className}>
                <div className={`input-wrapper input-icons ${props.inputClassName}`} >
                    <div className={"input-border-box" + (props.disabled ? " disabled" : "") + (focusState ? " focused": "")}>
                        <div>
                        <div className={`box-title ${props.titleClassName}`} >
                            {props.label}
                        </div>
                        <div className={`box-contents ${props.paddingClassName}`} id="box-contents">
                            <input type = {props.type}
                                    name={props.name}
                                    id={props.id}
                                    className="msisdninputbox input-field"
                                    autoComplete="off"
                                    minLength = {props.minLength}
                                    maxLength = {props.maxLength}
                                    placeholder={props.placeholder}
                                    onFocus={handleFocus}
                                    onChange={props.handleChange}
                                    onKeyPress={props.handleKey}
                                    onBlur={handleBlur}
                                    value={props.value}
                                    disabled={props.disabled}
                                    data-testid={props.dataTestID}
                                    />  
                            {props.showIcon && <i id={props.id+`Icon`} className={`fa fa-user icon ${props.iconClass}`} onClick={props.iconClick} ></i>}
                        </div>
                        </div>
                    </div>
                    <div className={`box-error`}>
                        {<i id="inputIcon" className={`fa fa-user inline-error-icon`} ></i>}<span>{props.errMsg}</span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Input;
